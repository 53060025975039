import('../sass/project.scss');


document.addEventListener("DOMContentLoaded", function() {
    var toggler = document.getElementById("navbarToggler");
    var content = document.getElementById("navbarContent");

    toggler.addEventListener("click", function() {
        content.classList.toggle("show");
    });
});
